(function () {
	'use strict';

	angular
		.module('app')
		.directive('groupSettings', ['firebaseIO', groupSettings]);

	function groupSettings(firebaseIO) {
		return {
			restrict: 'AE',
			scope: true,
			controller: function (scope, element, attrs) {},
			templateUrl: 'app/settings/account/edit-group-settings.html',
		};
	}
})();
